import * as React from 'react'
import styled from '@emotion/styled'

import { dimensions, widths } from '../styles/variables'
import { getEmSize } from '../styles/mixins'

const StyledPage = styled.div`
  display: block;
  position: relative;
  min-width: ${getEmSize(widths.sm)}em;
  padding-left: ${dimensions.containerPadding}px;
  padding-right: ${dimensions.containerPadding}px;
  padding-bottom: ${dimensions.containerPadding}px;
  margin-bottom: 2rem;
`

interface PageProps {
  className?: string
}

const Page: React.FC<PageProps> = ({ children, className }) => <StyledPage className={className}>{children}</StyledPage>

export default Page
