import * as React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'

import Page from '../components/Page'
import SEO from '../components/seo'
import Container from '../components/Container'
import { ThemeContext } from '../contexts/themeContext'

type Props = {
  data: {
    markdownRemark: {
      id: string
      html: string
      excerpt: string
      frontmatter: {
        name: string
        title: string
        route: string
      }
      headings: {
        value: string
        depth: string
      }[]
    }
  }
}

const PageTemplate: React.SFC<Props> = ({ data }) => {
  const { theme, isDark } = React.useContext(ThemeContext)
  const {
    html,
    frontmatter: { name }
  } = data.markdownRemark

  const Content = styled.article`
    pre {
      background: #3d4257;
      border-radius: 8px;
      padding: 0;
      margin-bottom:20px;
    }
    code {
      background: #3d4257;
      display: inline-block;
      position: relative;
      padding: 16px;
      font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    }
    .markdown-body .highlight pre,
    .markdown-body pre {
      padding: 16px;
      overflow: auto;
      font-size: 85%;
      line-height: 1.45;
      border-radius: 3px;
    }
    p code {
      background: #3d4257;
    }

    ul {
      padding: 0;
      list-style: none;
    }

    .intro-img {
      max-height: 300px;
      width: 100%;
      object-fit: cover;
      border-radius: 30px;
      margin-bottom: 30px;
    }

    .product-container {
      width: 100%;
      max-width: 800px;
      display: flex;
      border-radius: 30px;
    }

    .product-link {
      width: 33.33%;
    }

    .product-img-node {
      margin: 0px;
      padding: 0px;
      border-radius: 20px 0px 0px 20px;
    }
    .product-img-exp {
      margin: 0px;
      padding: 0px;
      border-radius: 0px;
    }
    .product-img-proto {
      margin: 0px;
      padding: 0px;
      border-radius: 0px 20px 20px 0px;
    }

    .home__block {
      display: flex;
      max-width: 800px;
      border-radius: 15px;
      background-color: ${theme.cardDefault};
      padding: 24px;
      margin-bottom: 16px;
      align-items: center;
      img {
        margin-right: 18px;
        border: none;
      }
      h4 {
        margin: 0;
      }
      p {
        margin: 0;
      }
    }

    .home__section {
      margin-bottom: 80px !important;
    }

    .anchor.before svg,
    .anchor.after svg {
      fill: ${theme.mainPrimary};
    }
  `
  return (
    <Page>
      <SEO title={name} />
      <Container>
        {/* eslint-disable-next-line react/no-danger */}
        <Content id="pageHtml" className={`page__${name.toLowerCase()}`} dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </Page>
  )
}

export default PageTemplate

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt
      html
      frontmatter {
        title
        route
        name
      }
      headings {
        value
        depth
      }
    }
  }
`
